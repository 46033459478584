/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Card, Grid, Label } from "semantic-ui-react";

import { Popover } from "components/common";

import LABEL from "constants/label";
import { formatDateMonthDDYear } from "helpers/utils";
import { getFilteredObjectForAdobe, getStringForAdobe, isMD, getDifferenceInMonths } from "pages/helpers/utils";

// import sendEventData from "analytics/analytics";
// import { EVENTS, TRIGGERS } from "analytics/constants";

import ViewCaseDetails from "components/ViewCaseDetails";
import CaseTitle from "./CaseTitle";
import ExpertiseSkillsListContainer from "../ExpertiseSkillsListContainer";
import { sanitizeData } from "../Limiter/Text/helper/utils";
import "./styles.scss";

/**
 * Case - Single case card
 *
 * @param  {object} caseObj Case data for Topics Paths popup
 * @param  {number} userId user hrid ID
 * @param  {array}  uniqueIndustryPAs filter options
 * @param  {array} industryFilter selected industry filter
 * @param  {array}  uniqueFunctionalPAs filter options
 * @param  {array} functionFilter selected function filter
 * @param  {string} sortBy Sort by
 *
 * @return Card component
 *
 * @example  <Case key={id} caseObj={caseObj} staffId= {staffId} />
 */

const Case = ({
  caseObj,
  userId,
  uniqueIndustryPAs,
  industryFilter,
  uniqueFunctionalPAs,
  functionFilter,
  sortBy,
  dispatch,
  pageNumber,
  uniqueCaseTypes,
  caseTypeFilter,
  dateFilterOptions,
  dateFilter,
  caseVignettesOnly,
  docRank,
  isEditable,
  isLoading,
  profile,
  casesLoading,
  linkCases,
  hideTopicsAndSkills,
  caseExpertise = [],
  caseSkills = [],
  isCaseTopicsAndSkillsLoading,
  isOverviewPage
}) => {
  const {
    crawl_item_id,
    case_number,
    case_type,
    date_opened,
    date_closed,
    case_short_desc,
    office_name,
    casevignettes,
    client_name,
    industrypas,
    functionalpas,
    case_hours_by_staff,
    case_full_desc
  } = caseObj;
  const [openCaseEdit, setOpenCaseEdit] = useState(false);

  // Edit Cases Popup
  const openEditCasesPopup = () => {
    if (!isCaseTopicsAndSkillsLoading) {
      setOpenCaseEdit(true);
    }
  };

  const closeEditCasesPopup = () => {
    setOpenCaseEdit(false);
  };

  const { CASES } = LABEL;
  const CASES_TAG_COUNT = 2;

  const isMediumScreen = isMD();

  // get Total vignettes
  const vignettesCount = casevignettes?.length || 0;

  // get all tags
  const tagIndustry = industrypas?.map((value) => value.topic_name_alias) ?? [];
  const tagFunctional = functionalpas?.map((value) => value.topic_name_alias) ?? [];
  const tags = [...tagIndustry, ...tagFunctional];
  // get Total case hours from cases object

  const caseHrs = (case_hours_by_staff || [])
    .filter((item) => item?.hr_id === userId)
    .reduce((total, item) => total + (item.tb_hours || 0), 0);

  /**
   *
   * @returns all applied filters, in case of no filters, it will be empty ""
   */

  const getFilterterm = () => {
    const textArray = [];
    if (industryFilter.length) {
      const filteredOptions = getFilteredObjectForAdobe(industryFilter, uniqueIndustryPAs);
      textArray.push(getStringForAdobe("industrypa=", filteredOptions));
    }

    if (functionFilter.length) {
      const filteredOptions = getFilteredObjectForAdobe(functionFilter, uniqueFunctionalPAs);
      textArray.push(getStringForAdobe("functionalpa=", filteredOptions));
    }
    if (caseTypeFilter.length) {
      const filteredOptions = getFilteredObjectForAdobe(caseTypeFilter, uniqueCaseTypes);
      textArray.push(getStringForAdobe("casetype=", filteredOptions));
    }

    if (dateFilter.length) {
      const filteredOptions = getFilteredObjectForAdobe(dateFilter, dateFilterOptions);
      textArray.push(getStringForAdobe("daterange=", filteredOptions));
    }
    if (caseVignettesOnly) {
      textArray.push("caseVignettes=/true/");
    }
    const result = textArray.join("|");
    return result || "NA";
  };

  const [trimmedDescription, setTrimmedDescription] = useState("");

  useEffect(() => {
    const sanitizedData = case_full_desc ? sanitizeData(case_full_desc) : "";
    setTrimmedDescription(sanitizedData);
  }, [caseObj?.case_full_desc]);

  // Render Topics/Skills

  const topicsList = [...caseExpertise];
  const skillsList = [...caseSkills];

  if (topicsList?.length === 0) {
    topicsList.push({ name: "--" });
  }
  if (skillsList?.length === 0) {
    skillsList.push({ name: "--" });
  }

  return (
    <>
      <Card className="case p-0">
        <Card.Content className="p-0">
          <Grid className="m-0">
            <Grid.Row className="case__customGrid p-0">
              {/* Card aside section */}
              <Grid.Column
                computer={4}
                tablet={isMediumScreen ? 4 : 16}
                className="case__caseAside-readOnly custom-grid__aside"
              >
                <p className="d-flex ">
                  <div className="mr-1">
                    <span> {formatDateMonthDDYear(date_opened)}</span>
                    <br />
                    <span data-testid="date_closed">
                      {date_closed ? formatDateMonthDDYear(date_closed) : "Present"}
                    </span>
                    <br />
                    <span data-testid="caseDuration">
                      {date_closed
                        ? `(${getDifferenceInMonths(new Date(date_opened), new Date(date_closed))} mos)`
                        : `(${getDifferenceInMonths(new Date(date_opened), new Date())} mos)`}
                    </span>
                  </div>
                  <p>{case_type}</p>
                </p>
                <p />
                <p className="case__caseHrs">
                  <p className="mb-0">{CASES.UsersHours}</p>
                  <p data-testid="caseHrs">{caseHrs || 0}</p>
                </p>
              </Grid.Column>
              {/* Card main section */}
              <Grid.Column computer={12} tablet={isMediumScreen ? 12 : 16} className="custom-grid__main pb-1 pr-2">
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      computer={isMediumScreen ? 16 : (hideTopicsAndSkills && 16) || 10}
                      tablet={16}
                      className="case__main"
                    >
                      {isMediumScreen ? (
                        <Grid.Row className="mt-1" style={{ flexWrap: "nowrap", justifyContent: "space-between" }}>
                          <div>
                            <CaseTitle
                              shortDescription={case_short_desc}
                              sortBy={sortBy}
                              docRank={docRank}
                              projectId={crawl_item_id}
                              getFilterterm={getFilterterm}
                            />
                          </div>
                        </Grid.Row>
                      ) : (
                        <Grid.Row className="mt-1 mb-0">
                          <div>
                            <CaseTitle
                              shortDescription={case_short_desc}
                              sortBy={sortBy}
                              docRank={docRank}
                              projectId={crawl_item_id}
                              getFilterterm={getFilterterm}
                              caseNumber={case_number || ""}
                            />
                          </div>
                        </Grid.Row>
                      )}

                      <Grid.Row className="mt-0">
                        {(case_full_desc && (
                          <div
                            role="presentation"
                            onClick={openEditCasesPopup}
                            onKeyUp={openEditCasesPopup}
                            className="description__full cursor-pointer"
                            dangerouslySetInnerHTML={{ __html: trimmedDescription }}
                          />
                        )) || <h4 className="case__nocaseDesc">{CASES.NoCaseDescription}</h4>}
                      </Grid.Row>
                    </Grid.Column>

                    <Grid.Column computer={6} tablet={16} className="custom-grid__topics pl-3">
                      <Grid>
                        <Grid.Row className="pb-0 mt-1">
                          {/* Topics box */}
                          {!hideTopicsAndSkills && (
                            <ExpertiseSkillsListContainer
                              isCaseTopicsAndSkillsLoading={isCaseTopicsAndSkillsLoading}
                              expertiseList={topicsList}
                              skillList={skillsList}
                              callback={openEditCasesPopup}
                            />
                          )}
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="case__tags-mobile pl-h">
                    <div style={isOverviewPage ? { width: "100%" } : { width: "63%" }}>
                      <p className="mb-0 case__caseMeta-readOnly">
                        {/* Showing/hiding fields based on values existance */}
                        {date_opened && moment(date_opened).format("MMMM YYYY")}
                        {client_name && (
                          <span style={{ marginRight: "10px", marginLeft: 0 }}>{` - ${client_name}`}</span>
                        )}
                        {office_name && (
                          <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
                            <img src="/icons/Location.svg" alt="" width={16} /> {office_name}
                          </span>
                        )}
                        {vignettesCount > 0 && <span>|</span>} {vignettesCount > 0 && <b>{vignettesCount}</b>}
                        {vignettesCount > 0 && ` ${CASES.Vignette}${vignettesCount > 1 ? "s" : ""}`}
                      </p>

                      {/* Tags */}
                      {(tags.length && (
                        <div className="d-flex">
                          {tags.slice(0, CASES_TAG_COUNT).map((tag) => (
                            <Label
                              data-testid="tags"
                              onClick={openEditCasesPopup}
                              className={`case__tag-readOnly overflow-hidden case__tag-readOnly__overflow ${
                                isCaseTopicsAndSkillsLoading ? "cursor-default" : "cursor-pointer"
                              }`}
                              key={tag}
                            >
                              {tag}
                            </Label>
                          ))}
                          {tags.length > CASES_TAG_COUNT && (
                            <Popover
                              trigger={
                                <Label
                                  data-testid="tagsCount"
                                  onClick={openEditCasesPopup}
                                  className={`case__tag-readOnly overflow-hidden ${
                                    isCaseTopicsAndSkillsLoading ? "cursor-default" : "cursor-pointer"
                                  }`}
                                >
                                  +{tags.length - CASES_TAG_COUNT}
                                </Label>
                              }
                              on="hover"
                              inverted
                              position="top left"
                              popper={{ className: "tags-tooltip" }}
                              offset={[0, 0]}
                              size="tiny"
                              disabled={isCaseTopicsAndSkillsLoading}
                            >
                              {CASES.ViewAll}
                            </Popover>
                          )}
                        </div>
                      )) || <p className="case__tag-readOnly__no-tags">{CASES.NoCaseTopics}</p>}
                    </div>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        {!hideTopicsAndSkills && (
          <div className="case_view-details">
            <p
              role="presentation"
              onClick={openEditCasesPopup}
              onKeyUp={openEditCasesPopup}
              className={isCaseTopicsAndSkillsLoading ? "disabled cursor-default" : ""}
            >
              {(!isEditable && CASES.ViewDetails) || CASES.ViewEditDetails}
            </p>
          </div>
        )}
        {openCaseEdit && (
          <ViewCaseDetails
            open={openCaseEdit}
            onClose={closeEditCasesPopup}
            onOpen={openEditCasesPopup}
            caseObj={caseObj}
            sortBy={sortBy}
            docRank={docRank}
            isEditable={isEditable}
            tags={tags}
            userId={userId}
            caseExpertise={caseExpertise}
            caseSkills={caseSkills}
            localDispatch={dispatch}
            pageNumber={pageNumber}
          />
        )}
      </Card>
    </>
  );
};

Case.defaultProps = {
  caseObj: {
    crawl_item_id: "",
    case_number: "",
    case_type: "",
    date_opened: "",
    date_closed: "",
    case_short_desc: "",
    office_name: "",
    casevignettes: [],
    client_name: "",
    industrypas: [],
    functionalpas: [],
    case_hours_by_staff: [],
    case_full_desc: "",
    clientDescription: "",
    allCaseTeams: [],
    topics: [],
    textDescription: "",
    caseVignettesStatus: ""
  },
  userId: null,
  uniqueIndustryPAs: [],
  industryFilter: [],
  uniqueFunctionalPAs: [],
  functionFilter: [],
  sortBy: "NA",
  caseTypeFilter: [],
  uniqueCaseTypes: [],
  dateFilterOptions: [],
  dateFilter: [],
  caseVignettesOnly: false,
  docRank: 0,
  isLoading: false,
  profile: {},
  linkCases: [],
  casesLoading: false,
  hideTopicsAndSkills: false,
  caseExpertise: [],
  caseSkills: [],
  isCaseTopicsAndSkillsLoading: false,
  dispatch: () => {},
  pageNumber: 0,
  isOverviewPage: false
};

Case.propTypes = {
  caseObj: PropTypes.shape({
    crawl_item_id: PropTypes.string,
    case_number: PropTypes.string,
    case_type: PropTypes.string,
    date_opened: PropTypes.string,
    date_closed: PropTypes.string,
    case_short_desc: PropTypes.string,
    office_name: PropTypes.string,
    casevignettes: PropTypes.arrayOf(PropTypes.object),
    client_name: PropTypes.string,
    industryAllPAs: PropTypes.arrayOf(PropTypes.object),
    functionalAllPAs: PropTypes.arrayOf(PropTypes.object),
    case_hours_by_staff: PropTypes.arrayOf(PropTypes.object),
    case_full_desc: PropTypes.string,
    clientDescription: PropTypes.string,
    allCaseTeams: PropTypes.arrayOf(PropTypes.object),
    topics: PropTypes.arrayOf(PropTypes.shape()),
    textDescription: PropTypes.string,
    caseVignettesStatus: PropTypes.string
  }),
  userId: PropTypes.number,
  uniqueIndustryPAs: PropTypes.arrayOf(PropTypes.object),
  industryFilter: PropTypes.arrayOf(PropTypes.string),
  uniqueFunctionalPAs: PropTypes.arrayOf(PropTypes.object),
  functionFilter: PropTypes.arrayOf(PropTypes.string),
  sortBy: PropTypes.string,
  caseTypeFilter: PropTypes.arrayOf(PropTypes.string),
  uniqueCaseTypes: PropTypes.arrayOf(PropTypes.object),
  dateFilterOptions: PropTypes.arrayOf(PropTypes.object),
  dateFilter: PropTypes.arrayOf(PropTypes.string),
  caseVignettesOnly: PropTypes.bool,
  docRank: PropTypes.number,
  isEditable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  profile: PropTypes.shape(),
  linkCases: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  casesLoading: PropTypes.bool,
  hideTopicsAndSkills: PropTypes.bool,
  caseExpertise: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  caseSkills: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  isCaseTopicsAndSkillsLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  pageNumber: PropTypes.number,
  isOverviewPage: PropTypes.bool
};

export default Case;
